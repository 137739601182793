import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import './all.sass'
import useSiteMetadata from './SiteMetadata'
import { connect } from 'react-redux'
const TemplateWrapper = ({ children, IsHungarian }) => {
    const { title, description, mainpitch } = useSiteMetadata()
    return (
        <div>
            <Helmet>
                <html lang="hu" />
                <title>{title}</title>
                <meta name="description" content={description} />
                
                <meta name="theme-color" content="#fff" />
                <meta property="og:type" content="business.business" />
                <meta property="og:title" content={title} />
                <meta property="og:url" content="/" />
                <meta property="og:image" content="/img/og-image.jpg" />
            </Helmet>
            <p>{mainpitch}</p>
            <div>
                <Link className="button is-large is-outlined" to="/">
                    {IsHungarian ? 'Vissza' : 'Zurück'}
                </Link>
                {children}
            </div>
        </div>
    )
}
export default connect(
    state => ({
        IsHungarian: state.app.IsHungarian,
    }),
    null
)(TemplateWrapper)
