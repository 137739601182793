import React from 'react'
import Fade from 'react-reveal/Fade'
const MyTable = ({ title, thead, tbody, text }) => {
    const Mythead = thead
    const Mytbody = tbody
    const MyThead = (
        <thead>
            <tr>
                {!!Mythead ? Mythead.map((number, index) => (
                    <th key={index.toString() + number}>{number}</th>
                )) : null}
            </tr>
        </thead>
    )
    const MyTbody = (
        <tbody>
            {!!Mytbody ? Mytbody.map(function (name, index) {
                if (!!name) {
                    return (
                        <tr key={index.toString() + name}>
                            {name.map(function (name2, index) {
                                return (
                                    <td key={index.toString() + name2}>
                                        {name2}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                }
                return null
            }) : null}
        </tbody>
    )
    return (
        <div>
            <Fade bottom>
                <h4>{title}</h4>

            </Fade>
            <div class="table-container">
                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth ">
                    {MyThead}
                    {MyTbody}
                </table>
            </div>
            {text}
        </div>
    )
}
export default MyTable
