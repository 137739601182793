import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/BlogLayout'
import MyTable from '../components/MyTable'
import { connect } from 'react-redux'
export const TableTemplate = ({
    title,
    thead,
    tbody,
    data
}) => {
    return (
        <section className="section">
            <div className="container content">
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                            {title}
                        </h1>
                        <MyTable thead={thead} tbody={tbody} data={data}/>
                    </div>
                </div>
            </div>
        </section>
    )
}
TableTemplate.propTypes = {
    title: PropTypes.string,
    thead: PropTypes.array,
    tbody: PropTypes.object,
}
const TablePost = ({ data,isHungarian }) => {
    const { markdownRemark: post } = data
    return (
        <Layout>
            <TableTemplate
                title={isHungarian?post.frontmatter.hu.title:post.frontmatter.de.title}
                thead={isHungarian?post.frontmatter.hu.thead:post.frontmatter.de.thead}
                tbody={isHungarian?post.frontmatter.hu.tbody:post.frontmatter.de.tbody}
                data={data}
            />
            
        </Layout>
    )
}
TablePost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
}
export default connect(
    state => ({
        isHungarian: state.app.isHungarian,
    }),
    null
)(TablePost)
export const pageQuery = graphql`
    query TablePostByID($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                templateKey
                date(formatString: "MMMM DD, YYYY")
                hu {
                    title
                    tags
                    thead
                    tbody
                }
                de {
                    title
                    tags
                    thead
                    tbody
                }
                
            }
        }
    }
`